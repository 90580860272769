@import "primeng/resources/themes/lara-light-blue/theme.css";

// /*High contrast override values to AAA for default theme*/

$high-contrast-blue: #204887;
$high-contrast-green: #0e672f;
$high-contrast-red: #af1919;
$high-contrast-grey: #505961;
$high-contrast-menuitem: #4d565e;
$high-contrast-dropdown-item-highlight: #d1e3fe;
$high-contrast-highlighted-grey: #414950;
$high-contrast-pagebackground-foregroundcolor: #ffffff;
$high-contrast-pagebackground-backgroundcolor: #37404c;
$high-contrast-label: #6b7280;
$high-contrast-disabled-field: #e9ecef;

.p-inputtext.ng-dirty.ng-invalid {
  border-color: $high-contrast-red !important;
  border: 1px solid;
}

.p-error {
  color: $high-contrast-red;
}

.hyperlink-color {
  color: #1a1c22;
}

.p-button-secondary {
  background-color: $high-contrast-blue;
  border: $high-contrast-blue;
  display: inline-block;
}

.p-button-success {
  background-color: $high-contrast-green;
  border: $high-contrast-green;
  display: inline-block;
}

.text-color-secondary {
  color: $high-contrast-grey !important;
}

::ng-deep .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  color: $high-contrast-menuitem;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: $high-contrast-blue;
  color: $high-contrast-blue;
}

.disabled-field {
  background-color: $high-contrast-disabled-field;
  cursor: not-allowed;
}

.disabled-calendar {
  .p-calendar .p-inputtext {
    @extend .disabled-field;
  }
}

.m-readOnly {
  background-color: $high-contrast-disabled-field;
  cursor: text;
}
label {
  color: $high-contrast-label;
}
